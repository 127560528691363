<template>
  <div class="quiz-container">
    <DxProgressBar
      :value="currentSubQuiz"
      :statusFormat="statusFormat"
      :showStatus="true"
      :showSubvalue="false"
      :min="0"
      :max="subQuizzes.length - 1"
      class="progress-bar"
    />
    <div v-if="!quizStarted && !showSummary && !showFinalSummary">
      <DxButton
        v-if="currentSubQuiz < subQuizzes.length"
        :text="`Starte ${subQuizNames[currentSubQuiz]}`"
        type="success"
        stylingMode="contained"
        @click="startQuiz"
      />
      <div v-else>
        <p>Alle Sub-Quizzes sind abgeschlossen!</p>
      </div>
    </div>
    <div v-else-if="showSummary && !showFinalSummary">
      <div class="timer">Verbleibende Zeit: {{ formattedTime }}</div>
      <div class="timer">Zeit abgelaufen!</div>
      <div class="quiz-content">
        <div>
          <p>Zusammenfassung {{ subQuizNames[currentSubQuiz] }}:</p>
          <p>Richtige Antworten: {{ correctAnswers }}</p>
          <p>Falsche Antworten: {{ wrongAnswers }}</p>
          <p>Unbeantwortete Fragen: {{ unansweredQuestions }}</p>
          <p>Gesamtanzahl Fragen: {{ subQuizzes[currentSubQuiz].length }}</p>
          <p>Prozentsatz richtig: {{ percentageCorrect }}%</p>
          <DxButton
            v-if="currentSubQuiz < subQuizzes.length - 1"
            :text="`Starte ${subQuizNames[currentSubQuiz + 1]}`"
            type="success"
            stylingMode="contained"
            @click="nextSubQuiz"
          />
          <DxButton
            v-else
            text="Zeige Endergebnis"
            type="success"
            stylingMode="contained"
            @click="showFinalResults"
          />
        </div>
      </div>
    </div>
    <div v-else-if="showFinalSummary">
      <h2>Endergebnis</h2>
      <div class="final-summary">
        <DxBarGauge
          :values="finalPercentages"
          :startValue="0"
          :endValue="100"
          :label="{
            visible: true,
            connectorWidth: 1,
            customizeText(arg) {
              return `${arg.valueText}% (${subQuizNames[arg.index]})`
            }
          }"
          :palette="['#E53935', '#FB8C00', '#FDD835', '#43A047', '#1E88E5', '#5E35B1']"
          :title="{
            text: 'Prozentuale Ergebnisse der Sub-Quizzes',
            font: { size: 18 }
          }"
          class="bar-gauge"
        >
        </DxBarGauge>
        <DxButton
          text="Quiz neu starten"
          type="success"
          stylingMode="contained"
          @click="restartQuiz"
        />
      </div>
    </div>
    <div v-else>
      <div class="timer">Verbleibende Zeit: {{ formattedTime }}</div>
      <div class="quiz-content">
        <div>
          <DxScrollView v-if="currentSubQuiz < 2" height="700px" width="100%" :showScrollbar="'always'">
            <!--<h3>{{ subQuizNames[currentSubQuiz] }}</h3>-->
            <div v-if="currentSubQuiz === 0">
              <h3>{{ subQuizNames[currentSubQuiz] }}</h3>
              <div v-for="(question, index) in subQuizzes[currentSubQuiz]" :key="index" class="question-item">
                <p>{{ question.question }}</p>
                <DxTextBox
                  v-model="userAnswers[index]"
                  mode="number"
                  placeholder="Antwort eingeben"
                  editorOptions.step="0"
                  step="0"
                />
              </div>
              <DxButton
                text="Antworten einreichen"
                type="default"
                stylingMode="contained"
                @click="submitQuiz"
                class="button"
              />
              <div style="height: 200px;"></div>
            </div>
            <div v-else-if="currentSubQuiz === 1">
              <!--<h3>{{ subQuizNames[currentSubQuiz] }}</h3>-->
              <div v-for="(question, index) in subQuizzes[currentSubQuiz]" :key="index" class="question-item">
                <p>{{ question.question }}</p>

                <!--<p>Sind diese Zahlen gleich?</p>-->
            <p>{{ question.num1 }} - {{ question.num2 }}</p>
             <DxRadioGroup
              :items="options"
              v-model="userAnswers[index]"
              displayExpr="text"
              valueExpr="value"
              layout="horizontal"
            />

            <!--<DxButton
              text="Gleich"
              type="default"
              stylingMode="contained"
              @click="() => checkEquality(true)"
              class="button"
            />
            <DxButton
              text="Ungleich"
              type="default"
              stylingMode="contained"
              @click="() => checkEquality(false)"
              class="button"
            />-->

              </div>
              <DxButton
                text="Antworten einreichen"
                type="default"
                stylingMode="contained"
                @click="submitQuiz"
                class="button"
              />
              <div style="height: 200px;"></div>
            </div>
          </DxScrollView>
          <div v-if="currentSubQuiz == 2" style="padding-left: 150px;">
            <DxScrollView height="900px" width="100%" :showScrollbar="'always'">
              <img v-if="getCurrentText().text" :src="getCurrentText().text" />
              <img :src="getCurrentQuestion().question" />
              <DxRadioGroup
                v-model="userAnswer"
                :items="getCurrentQuestion().answers"
                layout="vertical">
              </DxRadioGroup>
              <DxButton
                text="Antwort einreichen"
                type="default"
                stylingMode="contained"
                @click="checkAnswer"
                class="button"
              />
              <div style="height: 200px;"></div>
              <p v-if="feedback" :class="feedbackClass">{{ feedback }}</p>
            </DxScrollView>
          </div>
          <div v-if="currentSubQuiz >= 3">
            <p v-if="getCurrentText().text">{{ getCurrentText().text }}</p>
            <p>{{ getCurrentQuestion().question }}</p>
            <DxRadioGroup
              v-model="userAnswer"
              :items="getCurrentQuestion().answers"
              layout="vertical">
            </DxRadioGroup>
            <div style="height: 200px;"></div>
            <DxButton
              text="Antwort einreichen"
              type="default"
              stylingMode="contained"
              @click="checkAnswer"
              class="button"
            />
            
            <p v-if="feedback" :class="feedbackClass">{{ feedback }}</p>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxRadioGroup } from 'devextreme-vue/radio-group';
import { DxBarGauge } from 'devextreme-vue/bar-gauge';
import { DxScrollView } from 'devextreme-vue/scroll-view';

export default {
  name: 'QuizButton',
  components: {
    DxButton,
    DxProgressBar,
    DxTextBox,
    DxRadioGroup,
    DxBarGauge,
    DxScrollView
  },
  data() {
  return {
    subQuizNames: [
      'Rechentest',
      'Zahlen vergleichen',
      'Textverständnis',
      'Logisches und Rationales Denken',
      'Soziale Intelligenz'
    ],
    timeLimits: [150, 150, 240, 600, 1800], // Configurable time limits for each sub-quiz in seconds
    timeRemaining: 0,
    quizStarted: false,
    timer: null,
    currentSubQuiz: 0,
    currentQuestionIndex: 0,
    userAnswer: '',
    feedback: '',
    feedbackClass: '',
    correctAnswers: 0,
    wrongAnswers: 0,
    showSummary: false,
    showFinalSummary: false,
    subQuizzes: [],
    subQuizResults: [],
    textData: [], // Store text data for Textverständnis
    logicData: [], // Store text data for Logisches und Rationales Denken
    socialData: [], // Store text data for Soziale Intelligenz
    selectedTextData: [], // Selected text data for Textverständnis
    selectedLogicData: [], // Selected text data for Logisches und Rationales Denken
    selectedSocialData: [], // Selected text data for Soziale Intelligenz
    textIndex: 0, // Track text index for current question
    unansweredQuestions: 0,
    userAnswers: [],
    options: [
        { text: 'gleich', value: '1' },
        { text: 'ungleich', value: '2' }
      ],
  };
},
  mounted() {
    this.fetchQuizData();
  },
  watch: {
    quizStarted() {
      if (this.quizStarted) {
        this.focusInput();
      }
    },
    currentQuestionIndex() {
      this.focusInput();
    }
  },
  methods: {
    submitQuiz() {
      this.correctAnswers = 0;
      this.wrongAnswers = 0;

      this.subQuizzes[this.currentSubQuiz].forEach((question, index) => {

        if (this.currentSubQuiz === 0) {
          if (parseInt(this.userAnswers[index]) === question.answer) {
            this.correctAnswers++;
          } else {
            this.wrongAnswers++;
          }
        } else {
          console.log("Answer: " + this.userAnswers[index]);
          console.log("Correct Answer: " + question.answer);
          if (parseInt(this.userAnswers[index]) === question.answer) {
            this.correctAnswers++;
          } else {
            this.wrongAnswers++;
          }
        }

        
      });

      this.showSummary = true;
    },
    fetchQuizData() {
      Promise.all([
        fetch('/quiz_data.json').then(response => response.json()),
        fetch('/logic_data.json').then(response => response.json()),
        fetch('/social_data.json').then(response => response.json())
      ]).then(([textData, logicData, socialData]) => {
        this.textData = textData;
        this.logicData = logicData;
        this.socialData = socialData;
        this.subQuizzes = [
          this.generateMathQuestions(18),
          this.generateEqualityQuestions(40),
          this.generateTextQuestions(),
          this.generateLogicQuestions(),
          this.generateSocialQuestions()
        ];
      });
    },
    focusInput() {
      this.$nextTick(() => {
        const inputComponent = this.$refs.answerInput?.instance;
        if (inputComponent) {
          inputComponent.focus();
        }
      });
    },
    startQuiz() {
      this.timeRemaining = this.timeLimits[this.currentSubQuiz];
      this.quizStarted = true;
      this.currentQuestionIndex = 0;
      this.feedback = '';
      this.correctAnswers = 0;
      this.wrongAnswers = 0;
      this.unansweredQuestions = 0;
      this.showSummary = false;
      this.textIndex = 0;
      this.startTimer();
      
      this.userAnswers = Array(this.subQuizzes[this.currentSubQuiz].length).fill('');
    
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          this.stopQuiz();
        }
      }, 1000);
    },
    stopQuiz() {

      if (this.currentSubQuiz === 0 || this.currentSubQuiz === 1) {
        this.submitQuiz();
      }

      clearInterval(this.timer);
      this.showSummary = true;
      // Count remaining unanswered questions
      const unanswered = this.subQuizzes[this.currentSubQuiz].length - this.currentQuestionIndex - 1;
      this.unansweredQuestions = unanswered;
      // Do not update wrongAnswers here, as we'll count unanswered as wrong in the percentage calculation
      // Save results
      const totalQuestions = this.subQuizzes[this.currentSubQuiz].length;
      const percentageCorrect = (this.correctAnswers / totalQuestions) * 100;
      this.subQuizResults.push(percentageCorrect);
    },
    checkAnswer() {
      const currentQuestion = this.getCurrentQuestion();
      let correctAnswer;
      if (this.currentSubQuiz === 0 || this.currentSubQuiz === 1) {
        correctAnswer = currentQuestion.answer;
      } else {
        correctAnswer = currentQuestion.answers[currentQuestion.correct_answer];
      }

      if (this.userAnswer == correctAnswer) {
        this.correctAnswers++;
        this.feedback = 'Richtige Antwort!';
        this.feedbackClass = 'feedback-correct';
      } else {
        this.wrongAnswers++;
        this.feedback = 'Falsche Antwort!';
        this.feedbackClass = 'feedback-wrong';
      }
      this.unansweredQuestions = Math.max(0, this.unansweredQuestions - 1);
      this.nextQuestion();
    },
    checkEquality(isEqual) {
      const currentQuestion = this.getCurrentQuestion();
      if (currentQuestion) {
        const correct = currentQuestion.num1 === currentQuestion.num2;
        if (isEqual === correct) {
          this.correctAnswers++;
          this.feedback = 'Richtig!';
          this.feedbackClass = 'feedback-correct';
        } else {
          this.wrongAnswers++;
          this.feedback = 'Falsch!';
          this.feedbackClass = 'feedback-wrong';
        }
        this.unansweredQuestions = Math.max(0, this.unansweredQuestions - 1);
        this.nextQuestion();
      }
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.subQuizzes[this.currentSubQuiz].length - 1) {
        this.currentQuestionIndex++;
        this.userAnswer = ''; // Clear the answer input field
        if (this.currentSubQuiz === 2) {
          this.textIndex = this.currentQuestionIndex; //Math.floor(this.currentQuestionIndex / 4);
        } else if (this.currentSubQuiz === 3 || this.currentSubQuiz === 4) {
          this.textIndex = this.currentQuestionIndex;
        }
      } else {
        this.stopQuiz();
      }
    },
    nextSubQuiz() {
      this.currentSubQuiz++;
      this.quizStarted = false;
      this.startQuiz();
    },
    showFinalResults() {
      this.showFinalSummary = true;
      this.showSummary = false;
    },
    restartQuiz() {
      this.currentSubQuiz = 0;
      this.subQuizResults = [];
      this.showFinalSummary = false;
      this.quizStarted = false;
    },
    generateMathQuestions(count) {
      const questions = [];
      const operations = ['+', '-', '*', '/'];
      for (let i = 1; i <= count; i++) {
        const difficulty = Math.floor(i / 6) + 1; // Increases every 6 questions
        let a = Math.floor(Math.random() * (10 * difficulty)) + 1;
        let b = Math.floor(Math.random() * (10 * difficulty)) + 1;
        const operation = operations[Math.floor(Math.random() * operations.length)];
        let question, answer;

        switch (operation) {
          case '+':
            question = `Frage ${i}: Was ist ${a} + ${b}?`;
            answer = a + b;
            break;
          case '-':
            if (a < b) {
              [a, b] = [b, a]; // Ensure a is greater than or equal to b
            }
            question = `Frage ${i}: Was ist ${a} - ${b}?`;
            answer = a - b;
            break;
          case '*':
            a = Math.floor(a / 2) + 1; // Reduce a bit for multiplication
            b = Math.floor(b / 2) + 1; // Reduce a bit for multiplication
            question = `Frage ${i}: Was ist ${a} * ${b}?`;
            answer = a * b;
            break;
          case '/':
            answer = Math.floor(Math.random() * (5 * difficulty)) + 1;
            a = answer * b; // Ensure the division is whole
            question = `Frage ${i}: Was ist ${a} / ${b}?`;
            break;
        }
        questions.push({ question, answer });
      }
      return questions;
    },
    generateEqualityQuestions(count) {
      const questions = [];
      for (let i = 1; i <= count; i++) {
        let num1 = Math.floor(1000 + Math.random() * 90000000).toString(); // Generate number between 4 and 8 digits
        let num2;
        if (Math.random() > 0.5) {
          num2 = num1; // Make them equal half of the time
        } else {
          num2 = num1.split(''); // Split into array to change a digit
          const indexToChange = Math.floor(Math.random() * num2.length); // Choose a random index to change
          let newDigit;
          do {
            newDigit = Math.floor(Math.random() * 10).toString(); // Generate a new digit
          } while (newDigit === num2[indexToChange]); // Ensure the new digit is different
          num2[indexToChange] = newDigit;
          num2 = num2.join(''); // Join back into a string
        }
        questions.push({ num1: parseInt(num1, 10), num2: parseInt(num2, 10), answer: num1 === num2 ? 1 : 2 });
      }
      return questions;
    },
    generateTextQuestions() {
      const selectedTexts = [];
      const questions = [];

      // Randomly select 3 texts
      const textIndexes = this.textData.keys(); //.slice(0,12); //this.shuffleArray([...Array(this.textData.length).keys()]).slice(0, 12);
      textIndexes.forEach(index => {
        selectedTexts.push(this.textData[index]);
        questions.push(...this.textData[index].questions);
      });

      this.selectedTextData = selectedTexts;
      return questions;
    },
    generateLogicQuestions() {
    const selectedTexts = [];
    const questions = [];

    // Randomly select 8 questions
    const textIndexes = this.shuffleArray([...Array(this.logicData.length).keys()]).slice(0, 8);
    textIndexes.forEach(index => {
      selectedTexts.push(this.logicData[index]);
      questions.push(...this.logicData[index].questions);
    });

    this.selectedLogicData = selectedTexts;
    return questions;
  },
  generateSocialQuestions() {
    const selectedTexts = [];
    const questions = [];

    // Randomly select 8 questions
    const textIndexes = this.shuffleArray([...Array(this.socialData.length).keys()]).slice(0, 8);
    textIndexes.forEach(index => {
      selectedTexts.push(this.socialData[index]);
      questions.push(...this.socialData[index].questions);
    });

    this.selectedSocialData = selectedTexts;
    return questions;
  },
    getCurrentQuestion() {
      return this.subQuizzes[this.currentSubQuiz][this.currentQuestionIndex];
    },
    getCurrentText() {
      if (this.currentSubQuiz === 2) {
        return this.selectedTextData[this.textIndex] || {};
      } else if (this.currentSubQuiz === 3) {
        return this.selectedLogicData[this.textIndex] || {};
      } else if (this.currentSubQuiz === 4) {
        return this.selectedSocialData[this.textIndex] || {};
      }
      return {};
    },
    shuffleArray(array) {
      let currentIndex = array.length, randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    onInput(e) {
      this.userAnswer = e.component.option('text');
    }
  },
  computed: {
    percentageCorrect() {
    const totalQuestions = this.subQuizzes[this.currentSubQuiz].length;
    return totalQuestions ? ((this.correctAnswers / totalQuestions) * 100).toFixed(2) : 0;
  },
    statusFormat() {
      return () =>
        `${this.subQuizNames[this.currentSubQuiz]} (${this.currentSubQuiz + 1} von ${
          this.subQuizzes.length
        })`;
    },
    formattedTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    finalPercentages() {
      return this.subQuizResults.map(result => result);
    }
  }
};
</script>

<style>

.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.timer {
  font-size: 20px;
  margin-bottom: 20px;
}

.quiz-content {
  font-size: 18px;
}

.feedback {
  font-size: 18px;
  margin-top: 10px;
}

.feedback-correct {
  color: green;
}

.feedback-wrong {
  color: red;
}

.progress-bar {
  width: 100%;
  margin-bottom: 20px;
}

.final-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.bar-gauge {
  width: 300%;
  height: 400px;
}

.button {
  margin: 15px;
}

html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}
</style>
